
import SearchResultTableHeaders from "~/components/explore/SearchResultTableHeaders.vue";
import SearchResultTableCellProperty from "~/components/explore/SearchResultTableCellProperty.vue";
import SearchResultTableCellCompany from "~/components/explore/SearchResultTableCellCompany.vue";
import SearchResultTableCellPerson from "~/components/explore/SearchResultTableCellPerson.vue";
import SearchResultTableCellAll from "~/components/explore/SearchResultTableCellAll.vue";

export default {
  components: {
    SearchResultTableHeaders,
    SearchResultTableCellProperty,
    SearchResultTableCellCompany,
    SearchResultTableCellPerson,
    SearchResultTableCellAll,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },

    results: {
      type: Array,
      required: true,
    },
  },

  computed: {
    properties: function () {
      return this.results.filter((result) => result.typeOf === "PROPERTY");
    },

    companies: function () {
      return this.results.filter((result) => result.typeOf === "COMPANY");
    },

    people: function () {
      return this.results.filter((result) => result.typeOf === "PERSON");
    },

    headers: function () {
      return this.resultType !== "ALL" && this.results.length > 0 ? this.results[0].keyFigureLabels : [];
    },

    resultType: function () {
      if (this.results.length === 0) {
        return undefined;
      } else if (this.results.every((result) => result.typeOf === "PROPERTY")) {
        return "PROPERTY";
      } else if (this.results.every((result) => result.typeOf === "COMPANY")) {
        return "COMPANY";
      } else if (this.results.every((result) => result.typeOf === "PERSON")) {
        return "PERSON";
      } else {
        return "ALL";
      }
    },
  },

  methods: {
    saveToList(result) {
      this.$emit("save-to-list", result);
    },

    copyToClipboard(keyFigureLabels, keyFigures, addressText) {
      this.$emit("copy-to-clipboard", keyFigureLabels, keyFigures, addressText);
    },
  },
};
