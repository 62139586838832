
import MiniMap from "~/components/map/MiniMap.vue";
import Overlay from "./Overlay/Overlay.vue";

export default {
  components: { MiniMap, Overlay },

  props: {
    /**
     * state: "map", "list"
     */
    state: {
      type: String,
      required: true,
    },

    resultsCount: {
      type: Number,
      required: true,
    },

    saveSearchDisabled: {
      type: Boolean,
      required: true,
    },
  },
};
