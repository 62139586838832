
import SearchResultCardProperty from "~/components/explore/SearchResultCardProperty.vue";
import SearchResultCardCompany from "~/components/explore/SearchResultCardCompany.vue";
import SearchResultCardPerson from "~/components/explore/SearchResultCardPerson.vue";

export default {
  components: {
    SearchResultCardProperty,
    SearchResultCardCompany,
    SearchResultCardPerson,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    results: {
      type: Array,
      required: true,
    },
  },

  methods: {
    saveToList(result) {
      this.$emit("save-to-list", result);
    },

    copyToClipboard(keyFigureLabels, keyFigures, addressText) {
      this.$emit("copy-to-clipboard", keyFigureLabels, keyFigures, addressText);
    },
  },
};
