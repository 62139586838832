
export default {
  props: {
    filterObj: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      value: this.filterObj.value ? this.filterObj.value : undefined,
      rangeValue: this.filterObj.value ? this.filterObj.value : [undefined, undefined],
      suggestions: this.filterObj.suggestions ? this.filterObj.suggestions : [],
    };
  },

  computed: {
    sliderRangeHasValuesBelowMin() {
      return this.filterObj.valueMin < this.filterObj.valueP5;
    },

    sliderRangeHasValuesAboveMax() {
      return this.filterObj.valueMax > this.filterObj.valueP95;
    },

    showAsSlider() {
      return this.filterObj.type == "range" && this.filterObj.valueP95 > 0 && this.filterObj.valueP95 < 101;
    },

    selectMultipleItems() {
      return this.filterObj.suggestions.map((f) => {
        return { key: f, value: f, checked: this.value ? this.value.includes(f) : false };
      });
    },

    valueChanged() {
      if (this.showAsSlider) {
        return this.rangeValue[0] != this.filterObj.valueP5 || this.rangeValue[1] != this.filterObj.valueP95;
      } else if (this.filterObj.type == "range") {
        return this.rangeValue[0] != undefined || this.rangeValue[1] != undefined;
      } else if (this.filterObj.type == "keyword") {
        return this.filterObj.value != undefined && this.filterObj.value.length > 0;
      } else if (this.filterObj.type == "date") {
        return this.filterObj.value != undefined && this.filterObj.value.length > 0;
      } else if (this.filterObj.type === "polygon") {
        return this.filterObj.value != undefined && this.filterObj.value.length > 0;
      } else if (this.filterObj.type === "search") {
        return this.value != undefined && this.value != "";
      } else {
        return this.value != undefined;
      }
    },
  },

  watch: {
    valueChanged(val) {
      this.$emit("value-changed", { filter: this.filterObj.id, value: val });
    },

    filterObj: {
      handler(val) {
        // Note (Morten): special case for free text filter as it can be changed from outside
        if (val.type == "search") {
          this.value = val.value;
        }
      },
      deep: true,
    },
  },

  created() {
    if (this.rangeValue[0] == null && this.rangeValue[1] == null && this.showAsSlider) {
      this.rangeValue = [this.filterObj.valueMin, this.filterObj.valueMax];
    }
  },

  mounted() {
    if (this.valueChanged) {
      this.$emit("value-changed", { filter: this.filterObj.id, value: this.valueChanged });
    }
  },

  methods: {
    rangeValueLiveUpdate(v) {
      this.rangeValue = v;
    },

    toggleActiveFilter(e) {
      this.$store.commit("msga/upsertFilter", { ...this.filterObj, active: e });
    },

    upsertFilter(props) {
      this.$store.commit("msga/upsertFilter", { ...this.filterObj, ...props });
    },

    getMultiSelectCheckedState(value) {
      if (Array.isArray(this.filterObj.value)) {
        return this.filterObj.value.includes(value);
      } else {
        return this.filterObj.value === value;
      }
    },

    resetFilter() {
      // Morten: special case for select mutiple as it binds to a copy of the array
      // and not the original array. Should be fixed in the component.
      if (this.filterObj.type == "keyword") {
        this.$refs.selectMultiple.reset();
      } else if (this.filterObj.type == "polygon") {
        // Morten: special case for polygon as it is not a filter but a drawing. Should be fixed
        // in a future version of the map.
        this.$map.then((map) => {
          this.$store.commit("mapstate/setDrawingEnabled", false);

          map.fire("draw.deleteAllDrawings");

          if (this.$store.getters["mapstate/getPopupType"] == "AreaWindow") {
            this.$store.commit("mapstate/setPopupOpen", false);
          }
        });
      } else {
        this.value = undefined;
        this.rangeValue = [undefined, undefined];

        this.upsertFilter({ value: undefined });
      }
    },
  },
};
