import { getLatestTransaction } from "~/helpers/transaction-helpers.js";
import { getLatestValuation } from "~/helpers/valuation-helpers.js";
import { calculateWeightedArea, areaWeights } from "~/helpers/area-calculation-helpers.js";
import { getConstructionYears, getExtensionYears } from "~/helpers/property-helpers.js";

export const mapPropertyKeyFigures = function (propertySearchResult, filters) {
  const property = propertySearchResult.entity;
  return {
    bfeNumber: property.bfeNumber,
    type: property.type,
    latestPurchaseSum: filters.currency(getLatestTransaction(property)?.transactionInfo?.totalPurchaseSum),
    latestPurchaseDate: filters.shortdate(getLatestTransaction(property)?.handoverDate),
    squareMeterPrice: filters.currency(getLatestTransaction(property)?.transactionInfo?.totalPurchaseSum / calculateWeightedArea(property, areaWeights)),
    landValuation: filters.currency(getLatestValuation(property)?.landValuation),
    propertyValuation: filters.currency(getLatestValuation(property)?.propertyValuation),
    constructionYears: getConstructionYears(property)
      .filter((cy) => cy)
      .join(", "),
    extensionYears: getExtensionYears(property)
      .filter((ey) => ey)
      .join(", "),
    weightedAreas: filters.kvm(calculateWeightedArea(property, areaWeights)),
  };
};

export const mapCompanyKeyFigures = function (companySearchResult, filters) {
  const company = companySearchResult.entity;
  return {
    established: filters.shortdate(company.establishedAt),
    branchText: company.mainBranchText,
    typeText: company.typeText,
  };
};

export const mapPersonKeyFigures = function (personSearchResult, filters) {
  return {
    address: personSearchResult.fields.address,
    gender: personSearchResult.fields.gender,
    age: personSearchResult.fields.age,
  };
};
