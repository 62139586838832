// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout_-9tXc{display:flex;flex-direction:row;flex-grow:1;height:6.5rem;max-height:6.5rem;overflow:visible}.layout_-9tXc:not(:last-child){border-bottom:1px dashed var(--color-supportive-3-900);margin-bottom:.5rem;padding-bottom:1rem}.end_94Q4o{overflow:hidden}.end_94Q4o,.endTop_UyFif{display:flex;flex-direction:column;flex-grow:1}.endTop_UyFif{margin:.5rem .5rem 0;position:relative;-webkit-user-select:none;-moz-user-select:none;user-select:none}.endTopHeader_EAYUq{display:flex;flex-direction:column;padding-right:1.25rem}.endTopButton_F8qSr{display:flex;position:absolute;right:0;top:0;transition-duration:var(--transition-duration-shortest);transition-property:transform;transition-timing-function:ease-in-out;z-index:2}.endTopButton_F8qSr:hover{transform:scale(1.2)}.endBottom_LHk1X{align-items:flex-end;display:flex;flex-direction:row;flex-grow:1;padding-bottom:.75rem}.containerRange_jji0A{display:flex;flex-direction:column;margin:0 .5rem;width:100%}.boolean_Bpe1d{align-items:center}.boolean_Bpe1d,.copy_Id\\+Jl{display:flex;flex-direction:row;width:100%}.copy_Id\\+Jl{padding:0 .5rem}.date_4CNrZ{align-items:center;display:flex;flex-direction:row;width:100%}.copyRange_VssEX{display:flex;justify-content:center;padding:0 .5rem;width:-moz-fit-content;width:fit-content}.collapsed_eXN-p{display:flex;flex-direction:column}.description_JFtHM{display:flex;margin-left:1.9rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "layout_-9tXc",
	"end": "end_94Q4o",
	"endTop": "endTop_UyFif",
	"endTopHeader": "endTopHeader_EAYUq",
	"endTopButton": "endTopButton_F8qSr",
	"endBottom": "endBottom_LHk1X",
	"containerRange": "containerRange_jji0A",
	"boolean": "boolean_Bpe1d",
	"copy": "copy_Id+Jl",
	"date": "date_4CNrZ",
	"copyRange": "copyRange_VssEX",
	"collapsed": "collapsed_eXN-p",
	"description": "description_JFtHM"
};
module.exports = ___CSS_LOADER_EXPORT___;
