var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    "class": _vm.$style.container
  }, [_c('Composition', {
    attrs: {
      "loadingEnabled": "",
      "loading": _vm.filtersMetadataLoading || _vm.storeLoading,
      "loadingColor": "--color-supportive-3-300",
      "explodeHeight": ""
    },
    scopedSlots: _vm._u([{
      key: "layouts",
      fn: function fn() {
        return [_c('LayoutResultsSplit', {
          attrs: {
            "blockResults": _vm.resultState === 'map'
          },
          scopedSlots: _vm._u([{
            key: "mapToggle",
            fn: function fn() {
              return [_c('MapToggle', {
                attrs: {
                  "state": _vm.resultState,
                  "resultsCount": _vm.totalResults || 0,
                  "saveSearchDisabled": _vm.saveSearchDisabled
                },
                on: {
                  "toggle": _vm.mapToggleStateClick,
                  "save-search-to-list": _vm.saveSearchToList
                }
              })];
            },
            proxy: true
          }, {
            key: "filters",
            fn: function fn() {
              return [_c('WidgetBlank', {
                attrs: {
                  "colorBackground": "--color-white",
                  "shadow": "small",
                  "omitClickMore": "",
                  "rounded": "",
                  "omitPaddingBottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "container",
                  fn: function fn() {
                    return [_c('Accordion', {
                      attrs: {
                        "colorBackground": "--color-transparent",
                        "colorForeground": "--color-black-900",
                        "startsOpen": "",
                        "omitPadding": "",
                        "headerContainerSeparator": ""
                      },
                      scopedSlots: _vm._u([{
                        key: "copy",
                        fn: function fn() {
                          return [_c('KeyValuePair', {
                            attrs: {
                              "direction": "horizontal",
                              "spacingSize": "small"
                            },
                            scopedSlots: _vm._u([{
                              key: "containerStart",
                              fn: function fn() {
                                return [_c('Copy', {
                                  attrs: {
                                    "type": "label",
                                    "size": "large",
                                    "color": "--color-black-900"
                                  }
                                }, [_vm._v(" " + _vm._s(_vm.$t("FILTER_SECTION_GLOBAL")))])];
                              },
                              proxy: true
                            }, {
                              key: "containerEnd",
                              fn: function fn() {
                                return [_vm.getChangedFilters(_vm.globalFilters) > 0 ? _c('Label', {
                                  attrs: {
                                    "colorBackground": "--color-purple-50",
                                    "removeable": ""
                                  },
                                  scopedSlots: _vm._u([{
                                    key: "copy",
                                    fn: function fn() {
                                      return [_c('Copy', {
                                        attrs: {
                                          "type": "number",
                                          "size": "medium",
                                          "color": "--color-purple-900"
                                        }
                                      }, [_vm._v("\n                            " + _vm._s(_vm.$tc("NUMBER_ACTIVE_LABEL", _vm.getChangedFilters(_vm.globalFilters))) + "\n                          ")])];
                                    },
                                    proxy: true
                                  }], null, false, 4247173334)
                                }) : _vm._e()];
                              },
                              proxy: true
                            }])
                          })];
                        },
                        proxy: true
                      }, {
                        key: "container",
                        fn: function fn() {
                          return _vm._l(_vm.globalFilters, function (filter, index) {
                            return _c('FilterNaked', {
                              key: "g_filter".concat(index),
                              attrs: {
                                "filterObj": filter
                              },
                              on: {
                                "value-changed": _vm.filterValueChanged
                              }
                            });
                          });
                        },
                        proxy: true
                      }])
                    })];
                  },
                  proxy: true
                }])
              }), _vm._v(" "), _c('CompositionSpacer', {
                attrs: {
                  "direction": "vertical",
                  "size": "medium"
                }
              }), _vm._v(" "), _c('WidgetBlank', {
                attrs: {
                  "colorBackground": "--color-white",
                  "shadow": "small",
                  "omitClickMore": "",
                  "rounded": "",
                  "omitPaddingBottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "container",
                  fn: function fn() {
                    return [_c('div', {
                      "class": _vm.$style.filters
                    }, [_c('Accordion', {
                      attrs: {
                        "colorBackground": "--color-transparent",
                        "colorForeground": "--color-black-900",
                        "omitPadding": "",
                        "headerContainerSeparator": ""
                      },
                      scopedSlots: _vm._u([{
                        key: "copy",
                        fn: function fn() {
                          return [_c('KeyValuePair', {
                            attrs: {
                              "direction": "horizontal",
                              "spacingSize": "small"
                            },
                            scopedSlots: _vm._u([{
                              key: "containerStart",
                              fn: function fn() {
                                return [_c('Copy', {
                                  attrs: {
                                    "type": "label",
                                    "size": "large",
                                    "color": "--color-black-900"
                                  }
                                }, [_vm._v(_vm._s(_vm.$t("FILTER_SECTION_PROPERTY")))])];
                              },
                              proxy: true
                            }, {
                              key: "containerEnd",
                              fn: function fn() {
                                return [_vm.getChangedFilters(_vm.propertyFilters) > 0 ? _c('Label', {
                                  attrs: {
                                    "colorBackground": "--color-purple-50",
                                    "removeable": ""
                                  },
                                  scopedSlots: _vm._u([{
                                    key: "copy",
                                    fn: function fn() {
                                      return [_c('Copy', {
                                        attrs: {
                                          "type": "number",
                                          "size": "medium",
                                          "color": "--color-purple-900"
                                        }
                                      }, [_vm._v("\n                              " + _vm._s(_vm.$tc("NUMBER_ACTIVE_LABEL", _vm.getChangedFilters(_vm.propertyFilters))) + "\n                            ")])];
                                    },
                                    proxy: true
                                  }], null, false, 613962618)
                                }) : _vm._e()];
                              },
                              proxy: true
                            }])
                          })];
                        },
                        proxy: true
                      }, {
                        key: "container",
                        fn: function fn() {
                          return [_c('DropDown', {
                            attrs: {
                              "colorBackground": "--color-black-50",
                              "colorForeground": "--color-black-900",
                              "label": _vm.selectedPropertyType != null ? _vm.selectedPropertyType : _vm.$t('FILTER_DROPDOWN_PROPERTY_TYPE_DEFAULT')
                            },
                            model: {
                              value: _vm.selectedPropertyType,
                              callback: function callback($$v) {
                                _vm.selectedPropertyType = $$v;
                              },
                              expression: "selectedPropertyType"
                            }
                          }, _vm._l(_vm.propertyTypes, function (type, index) {
                            return _c('DropDownItem', {
                              key: index,
                              attrs: {
                                "value": type,
                                "label": type
                              }
                            });
                          }), 1), _vm._v(" "), _c('CompositionSpacer', {
                            attrs: {
                              "direction": "vertical",
                              "size": "small"
                            }
                          }), _vm._v(" "), _vm._l(_vm.propertyFilters, function (filter, index) {
                            return _c('FilterNaked', {
                              key: "p_filter".concat(index),
                              attrs: {
                                "filterObj": filter
                              },
                              on: {
                                "value-changed": _vm.filterValueChanged
                              }
                            });
                          })];
                        },
                        proxy: true
                      }])
                    })], 1)];
                  },
                  proxy: true
                }])
              }), _vm._v(" "), _c('CompositionSpacer', {
                attrs: {
                  "direction": "vertical",
                  "size": "medium"
                }
              }), _vm._v(" "), _c('WidgetBlank', {
                attrs: {
                  "colorBackground": "--color-white",
                  "shadow": "small",
                  "omitClickMore": "",
                  "rounded": "",
                  "omitPaddingBottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "container",
                  fn: function fn() {
                    return [_c('Accordion', {
                      attrs: {
                        "colorBackground": "--color-transparent",
                        "colorForeground": "--color-black-900",
                        "omitPadding": "",
                        "headerContainerSeparator": ""
                      },
                      scopedSlots: _vm._u([{
                        key: "copy",
                        fn: function fn() {
                          return [_c('KeyValuePair', {
                            attrs: {
                              "direction": "horizontal",
                              "spacingSize": "small"
                            },
                            scopedSlots: _vm._u([{
                              key: "containerStart",
                              fn: function fn() {
                                return [_c('Copy', {
                                  attrs: {
                                    "type": "label",
                                    "size": "large",
                                    "color": "--color-black-900"
                                  }
                                }, [_vm._v(" " + _vm._s(_vm.$t("FILTER_SECTION_VALUATION")))])];
                              },
                              proxy: true
                            }, {
                              key: "containerEnd",
                              fn: function fn() {
                                return [_vm.getChangedFilters(_vm.valuationFilters) > 0 ? _c('Label', {
                                  attrs: {
                                    "colorBackground": "--color-purple-50",
                                    "removeable": ""
                                  },
                                  scopedSlots: _vm._u([{
                                    key: "copy",
                                    fn: function fn() {
                                      return [_c('Copy', {
                                        attrs: {
                                          "type": "number",
                                          "size": "medium",
                                          "color": "--color-purple-900"
                                        }
                                      }, [_vm._v("\n                            " + _vm._s(_vm.$tc("NUMBER_ACTIVE_LABEL", _vm.getChangedFilters(_vm.valuationFilters))) + "\n                          ")])];
                                    },
                                    proxy: true
                                  }], null, false, 3740360270)
                                }) : _vm._e()];
                              },
                              proxy: true
                            }])
                          })];
                        },
                        proxy: true
                      }, {
                        key: "container",
                        fn: function fn() {
                          return _vm._l(_vm.valuationFilters, function (filter, index) {
                            return _c('FilterNaked', {
                              key: "g_filter".concat(index),
                              attrs: {
                                "filterObj": filter
                              },
                              on: {
                                "value-changed": _vm.filterValueChanged
                              }
                            });
                          });
                        },
                        proxy: true
                      }])
                    })];
                  },
                  proxy: true
                }])
              }), _vm._v(" "), _c('CompositionSpacer', {
                attrs: {
                  "direction": "vertical",
                  "size": "medium"
                }
              }), _vm._v(" "), _c('WidgetBlank', {
                attrs: {
                  "colorBackground": "--color-white",
                  "shadow": "small",
                  "omitClickMore": "",
                  "rounded": "",
                  "omitPaddingBottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "container",
                  fn: function fn() {
                    return [_c('Accordion', {
                      attrs: {
                        "colorBackground": "--color-transparent",
                        "colorForeground": "--color-black-900",
                        "omitPadding": "",
                        "headerContainerSeparator": ""
                      },
                      scopedSlots: _vm._u([{
                        key: "copy",
                        fn: function fn() {
                          return [_c('KeyValuePair', {
                            attrs: {
                              "direction": "horizontal",
                              "spacingSize": "small"
                            },
                            scopedSlots: _vm._u([{
                              key: "containerStart",
                              fn: function fn() {
                                return [_c('Copy', {
                                  attrs: {
                                    "type": "label",
                                    "size": "large",
                                    "color": "--color-black-900"
                                  }
                                }, [_vm._v(" " + _vm._s(_vm.$t("FILTER_SECTION_OWNER")))])];
                              },
                              proxy: true
                            }, {
                              key: "containerEnd",
                              fn: function fn() {
                                return [_vm.getChangedFilters(_vm.ownerFilters) > 0 ? _c('Label', {
                                  attrs: {
                                    "colorBackground": "--color-purple-50",
                                    "removeable": ""
                                  },
                                  scopedSlots: _vm._u([{
                                    key: "copy",
                                    fn: function fn() {
                                      return [_c('Copy', {
                                        attrs: {
                                          "type": "number",
                                          "size": "medium",
                                          "color": "--color-purple-900"
                                        }
                                      }, [_vm._v("\n                            " + _vm._s(_vm.$tc("NUMBER_ACTIVE_LABEL", _vm.getChangedFilters(_vm.ownerFilters))) + "\n                          ")])];
                                    },
                                    proxy: true
                                  }], null, false, 3312346748)
                                }) : _vm._e()];
                              },
                              proxy: true
                            }])
                          })];
                        },
                        proxy: true
                      }, {
                        key: "container",
                        fn: function fn() {
                          return _vm._l(_vm.ownerFilters, function (filter, index) {
                            return _c('FilterNaked', {
                              key: "g_filter".concat(index),
                              attrs: {
                                "filterObj": filter
                              },
                              on: {
                                "value-changed": _vm.filterValueChanged
                              }
                            });
                          });
                        },
                        proxy: true
                      }])
                    })];
                  },
                  proxy: true
                }])
              }), _vm._v(" "), _c('CompositionSpacer', {
                attrs: {
                  "direction": "vertical",
                  "size": "medium"
                }
              })];
            },
            proxy: true
          }, {
            key: "header",
            fn: function fn() {
              return [_c('Copy', {
                attrs: {
                  "type": "header",
                  "size": "medium",
                  "color": "--color-black-900"
                }
              }, [_vm._v(" " + _vm._s(_vm._f("number")(_vm.totalResults)) + " " + _vm._s(_vm.$t("EXPLORE_MENU_RESULT_COUNT")) + " ")])];
            },
            proxy: true
          }, !_vm.loading && _vm.totalResults > 0 ? {
            key: "controlsStart",
            fn: function fn() {
              return [_vm.saveSearchDisabled ? _c('ChromeTooltip', {
                attrs: {
                  "title": _vm.$t('ADVANCED_SEARCH_TOO_MANY_RESULTS_ERROR'),
                  "showOnHover": "",
                  "hoverEffectsDisable": ""
                }
              }, [_c('PushButton', {
                attrs: {
                  "size": "small",
                  "color": "--color-transparent",
                  "underline": "",
                  "collapse": "",
                  "omitPadding": ""
                },
                scopedSlots: _vm._u([{
                  key: "iconBefore",
                  fn: function fn() {
                    return [_c('Icon', {
                      attrs: {
                        "id": "bookmark",
                        "size": "large",
                        "color": "--color-black"
                      }
                    })];
                  },
                  proxy: true
                }, {
                  key: "copy",
                  fn: function fn() {
                    return [_c('Copy', {
                      attrs: {
                        "type": "body",
                        "size": "small",
                        "color": "--color-black"
                      }
                    }, [_c('strong', [_vm._v(_vm._s(_vm._f("lowercase")(_vm.$t("LIST_SAVE_ALL"))))])])];
                  },
                  proxy: true
                }], null, false, 3325638493)
              })], 1) : _c('PushButton', {
                attrs: {
                  "size": "small",
                  "color": "--color-transparent",
                  "underline": "",
                  "collapse": "",
                  "omitPadding": ""
                },
                on: {
                  "click": _vm.saveSearchToList
                },
                scopedSlots: _vm._u([{
                  key: "iconBefore",
                  fn: function fn() {
                    return [_c('Icon', {
                      attrs: {
                        "id": "bookmark",
                        "size": "large",
                        "color": "--color-black"
                      }
                    })];
                  },
                  proxy: true
                }, {
                  key: "copy",
                  fn: function fn() {
                    return [_c('Copy', {
                      attrs: {
                        "type": "body",
                        "size": "small",
                        "color": "--color-black"
                      }
                    }, [_c('strong', [_vm._v(_vm._s(_vm._f("lowercase")(_vm.$t("LIST_SAVE_ALL"))))])])];
                  },
                  proxy: true
                }], null, false, 3325638493)
              })];
            },
            proxy: true
          } : null, !_vm.loading ? {
            key: "controlsEnd",
            fn: function fn() {
              return [_c('KeyValuePair', {
                attrs: {
                  "direction": "vertical"
                },
                scopedSlots: _vm._u([{
                  key: "containerStart",
                  fn: function fn() {
                    return [_c('Copy', {
                      attrs: {
                        "type": "label",
                        "size": "small",
                        "color": "--color-black-900"
                      }
                    }, [_vm._v(_vm._s(_vm.$t("EXPLORE_LAYOUT")))])];
                  },
                  proxy: true
                }, {
                  key: "containerEnd",
                  fn: function fn() {
                    return [_c('DropDown', {
                      attrs: {
                        "label": _vm.displayModeLabel,
                        "colorForeground": "--color-black-900",
                        "colorBackground": "--color-black-50",
                        "minWidth": "16rem",
                        "macWidth": "16rem"
                      },
                      model: {
                        value: _vm.displayMode,
                        callback: function callback($$v) {
                          _vm.displayMode = $$v;
                        },
                        expression: "displayMode"
                      }
                    }, [_c('DropDownItem', {
                      attrs: {
                        "selected": _vm.displayMode === 'table',
                        "label": "Table",
                        "value": "table"
                      }
                    }), _vm._v(" "), _c('DropDownItem', {
                      attrs: {
                        "selected": _vm.displayMode === 'cards',
                        "label": "Cards",
                        "value": "cards"
                      }
                    })], 1)];
                  },
                  proxy: true
                }], null, false, 1157260422)
              })];
            },
            proxy: true
          } : null, {
            key: "results",
            fn: function fn() {
              return [_vm.totalResults === 0 ? _c('EmptyState', {
                attrs: {
                  "color": "--color-black-900",
                  "textHeader": _vm.$t('EMPTY_STATE_EXPLORE_RESULTS_TITLE'),
                  "textBody": _vm.$t('EMPTY_STATE_EXPLORE_RESULTS_SUBTITLE')
                }
              }) : _vm.displayMode === 'cards' ? _c('ResultCards', {
                attrs: {
                  "results": _vm.results,
                  "loading": _vm.loading
                },
                on: {
                  "save-to-list": _vm.addToList,
                  "copy-to-clipboard": _vm.copyToClipboard
                }
              }) : _vm.displayMode === 'table' ? _c('ResultTable', {
                attrs: {
                  "results": _vm.results,
                  "loading": _vm.loading
                },
                on: {
                  "save-to-list": _vm.addToList,
                  "copy-to-clipboard": _vm.copyToClipboard
                }
              }) : _vm._e()];
            },
            proxy: true
          }, {
            key: "pagination",
            fn: function fn() {
              return [!_vm.loading && _vm.totalResults > 0 ? _c('Pagination', {
                attrs: {
                  "resultsTotal": _vm.totalResults || 0,
                  "resultsPerPage": _vm.resultsPerPage,
                  "resultsCurrentPage": _vm.resultsCurrentPage,
                  "labelPagePrevious": _vm.$t('REFERENCE_PROPERTIES_LABEL_PAGE_PREVIOUS'),
                  "labelPageNext": _vm.$t('REFERENCE_PROPERTIES_LABEL_PAGE_NEXT'),
                  "labelPagePosition": _vm.$t('REFERENCE_PROPERTIES_LABEL_PAGE_POSITION'),
                  "hideResultsPerPage": ""
                },
                on: {
                  "page-next": function pageNext($event) {
                    return _vm.pageNext();
                  },
                  "page-previous": function pagePrevious($event) {
                    return _vm.pagePrevious();
                  },
                  "page-change": _vm.pageChange
                }
              }) : _vm._e()];
            },
            proxy: true
          }], null, true)
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c('portal', {
    attrs: {
      "to": "chrome-notification-layer-2"
    }
  }, [_c('AddToListDialog', {
    attrs: {
      "visible": _vm.showAddToList,
      "properties": _vm.propertiesToSave,
      "origin": "company portfolio"
    },
    on: {
      "close": function close($event) {
        _vm.showAddToList = false;
      },
      "properties-added": _vm.refetch
    }
  })], 1), _vm._v(" "), _vm.$store.getters['mapstate/getMapIsReady'] && _vm.$store.getters['mapstate/showAdvanvcedSearchResultsPins'] ? _c('SymbolLayer', {
    attrs: {
      "layer": _vm.msga_property_pins,
      "source": "msga-properties",
      "data": _vm.formatMSGAClusters(_vm.clusters),
      "visible": true
    },
    on: {
      "click": _vm.onClickPropertyPin
    }
  }) : _vm._e(), _vm._v(" "), _vm.$store.getters['mapstate/getMapIsReady'] ? _c('MapPositionUpdateLayer', {
    on: {
      "moveend": function moveend($event) {
        return _vm.$store.commit('msga/searchClusters', $event);
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };